.app {
    font-family: "Roboto", sans-serif;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
}