.main {
    display: grid;
    width: 80%;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
}


.title {
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
}

.add__images {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.box {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    border-radius: 30px;
    box-shadow: 0 0 3px #c1c0c8;
}

.checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.check__trail {
    display: flex;
    width: 80px;
    height: 42px;
    padding: 5px;
    background: white;
    box-shadow: 0 0 5px #007aef;
    border-radius: 200px;
    transition: all 0.5s ease;
    cursor: pointer;
}

.check__container {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    gap: 20px;
    justify-content: center;
    align-content: center;
    align-self: start;
}

.span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    position: relative;
    background: #007aef;
    border-radius: 50%;
    transition: all 0.5s ease;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 16px;
        background: #2c3e50;
        transition: all 0.5s ease;
    }
    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }
}

.checkbox:checked + .check__trail {
    box-shadow: 0 0 5px #117ffc;
    background: #117ffc;
    .span {
        --icon-color: #2c3e50;
        margin-left: 50%;
        background: #ecf0f1;
        &::before {
            transform: rotate(45deg) translate(2px, -2px);
        }
        &::after {
            height: 12px;
            transform: rotate(-45deg) translate(-4px, -2px);
        }
    }
}

.drop__area {
    width: 100%;
    aspect-ratio: 5/4;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    border-radius: 20px;
    background-color: #eee;
}

.image {
    cursor: pointer;
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.box__title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
}

.box__subtitle {
    margin: 20px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
}

.delete__button {
    margin-top: -7px;
    flex-shrink: 0;
    padding: 0;
    height: 20px;
    width: 20px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
}

.delete__image {
    width: 20px;
    height: 20px;
    object-fit: cover;
}

.notes__text {
    max-width: 400px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
}

.notes {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 5px;
}


.note {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
}

.input {
    width: 100%;
    background-color: #eee;
    padding: 10px 15px;
    border-radius: 30px;
    border: 1px solid #989898;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    margin-top: -12px;
}

.input:focus {
    outline: none !important;
}

.inputs__container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.input__container {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-items: center;
    align-items: start;
    justify-content: center;
    align-content: center;
}

.text__area {
    margin-top: -12px;
    width: 100%;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    background-color: #eee;
    height: 150px;
    border-radius: 30px;
    border: 1px solid #989898;
    padding: 8px 15px;
}

.text__area:focus {
    outline: none !important;
}

.add__note {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.submit__button {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background-color: #007aef;
    height: 50px;
    width: 200px;
    border-radius: 50px;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.submit__button:hover {
    opacity: 85%;
}

@media (max-width: 1450px) {
    .notes__text {
        max-width: 250px;
    }
    .note {
        gap: 5px;
    }

}

@media (max-width: 1000px) {
    .inputs__container {
        column-gap: 15px;
    }
    .main {
        align-self: start;
        margin-top: 50px;
        column-gap: 40px;
    }

    .title {
        font-size: 28px;
        line-height: 28px;
    }

    .add__images {
        gap: 18px;
    }

    .box {
        padding: 25px;
    }

    .drop__area {
        aspect-ratio: 1/1;
    }

    .image {
        width: 75px;
        height: 75px;
        margin-bottom: 10px;
    }

    .box__title {
        font-size: 13px;
        line-height: 13px;
    }

    .box__subtitle {
        margin: 16px 0;
        font-size: 10px;
        line-height: 10px;
    }

    .delete__button {
        margin-top: -16px;
        height: 16px;
        width: 16px;
    }

    .delete__image {
        width: 16px;
        height: 16px;
    }

    .notes__text {
        padding-bottom: 8px;
        overflow-x: scroll;
        max-width: 120px;
        font-size: 14px;
        line-height: 14px;
    }

    .note {
        gap: 3px;
    }

    .input {
        padding: 8px 12px;
        border-radius: 20px;
        font-size: 18px;
        line-height: 18px;
        margin-top: -14px;
    }

    .text__area {
        margin-top: -14px;
        font-size: 18px;
        line-height: 18px;
        height: 150px;
        border-radius: 20px;
        padding: 8px 12px;
    }

    .add__note {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .submit__button {
        height: 40px;
        width: 160px;
        border-radius: 40px;
        font-size: 20px;
        line-height: 20px;
    }

    .check__trail {
        width: 55px;
        height: 30px;
        padding: 3px;
        box-shadow: 0 0 3px #007aef;
        border-radius: 200px;
    }

    .check__container {
        gap: 15px;
    }

    .span {
        width: 24px;
        height: 24px;
        &::before,
        &::after {
            width: 3px;
            height: 12px;
        }
    }

    .checkbox:checked + .check__trail {
        box-shadow: 0 0 3px #117ffc;
        .span {
            &::before {
                transform: rotate(45deg) translate(2px, -2px);
            }
            &::after {
                height: 9px;
                transform: rotate(-45deg) translate(-3px, -2px);
            }
        }
    }
}

@media (max-width: 790px) {
    .main {
        margin-top: 20px;
        width: 85%;
        column-gap: 30px;
    }

    .title {
        font-size: 20px;
        line-height: 20px;
    }

    .add__images {
        gap: 12px;
    }

    .box {
        padding: 15px;
    }

    .drop__area {
        aspect-ratio: 1/1;
    }

    .image {
        width: 50px;
        height: 50px;
        margin-bottom: 5px;
    }

    .box__title {
        font-size: 10px;
        line-height: 10px;
    }

    .box__subtitle {
        margin: 3px 0;
        font-size: 8px;
        line-height: 8px;
    }

    .delete__button {
        margin-top: -18px;
        height: 10px;
        width: 10px;
    }

    .delete__image {
        width: 10px;
        height: 10px;
    }

    .notes__text {
        padding-bottom: 5px;
        overflow-x: scroll;
        max-width: 120px;
        font-size: 10px;
        line-height: 10px;
    }

    .input {
        padding: 5px 8px;
        border-radius: 15px;
        font-size: 14px;
        line-height: 14px;
        margin: -16px 0;
    }

    .text__area {
        margin: -16px 0;
        font-size: 14px;
        line-height: 14px;
        height: 90px;
        border-radius: 15px;
        padding: 5px 8px;
    }

    .add__note {
        gap: 20px;
    }

    .submit__button {
        height: 30px;
        width: 130px;
        border-radius: 30px;
        font-size: 16px;
        line-height: 16px;
    }


    .check__trail {
        width: 40px;
        height: 22px;
        padding: 3px;
        box-shadow: 0 0 1px #007aef;
        border-radius: 200px;
    }

    .check__container {
        gap: 10px;
        margin: 5px 0 -10px;
    }

    .span {
        width: 16px;
        height: 16px;
        &::before,
        &::after {
            width: 2px;
            height: 8px;
        }
    }

    .checkbox:checked + .check__trail {
        box-shadow: 0 0 1px #117ffc;
        .span {
            &::before {
                transform: rotate(45deg) translate(1px, -1px);
            }
            &::after {
                height: 6px;
                transform: rotate(-45deg) translate(-2px, -1px);
            }
        }
    }

}


@media (max-width: 550px) {
    .main {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }


    .drop__area {
        aspect-ratio: 2/1;
    }
}