.header {
    padding: 16px 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    justify-items: center;
    border: none;
    box-shadow: none;
    background-color: black;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-180deg);
    }
}

.themeIcon {
    flex-shrink: 0;
    rotate: 0deg;
    padding: 0;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    transition: transform 0.3s;
}

.themeIconRotate {
    animation: rotate 0.3s ease-in-out;
}


.themeButton {
    flex-shrink: 0;
    padding: 0;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
}

.header__nav {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-items: center;
    justify-content: center;
    align-items: center;
}

.image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.logo {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.button_l {
    flex-shrink: 0;
    padding: 0;
    height: 50px;
    width: 50px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
}

.button_m {
    flex-shrink: 0;
    padding: 0;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
}

.title {
    color: #fafafa;
    margin: 0;
    font-size: 28px;
    font-weight: 900;
    line-height: 28px;
}

.container {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
}

@media (max-width: 845px) {
    .header {
        padding: 10px 30px;
    }
    .image {
        width: 30px;
        height: 30px;
    }

    .logo {
        width: 30px;
        height: 30px;
    }

    .button_l {
        height: 30px;
        width: 30px;
    }

    .button_m {
        height: 30px;
        width: 30px;
    }

    .title {
        font-size: 24px;
        line-height: 24px;
    }

}

@media (max-width: 450px) {
    .header {
        padding: 6px 20px;
    }
    .image {
        width: 25px;
        height: 25px;
    }

    .logo {
        width: 25px;
        height: 25px;
    }

    .button_l {
        height: 25px;
        width: 25px;
    }

    .button_m {
        height: 25px;
        width: 25px;
    }

    .title {
        font-size: 20px;
        line-height: 20px;
    }

}
