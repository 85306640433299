.main {
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 45px;
    padding: 60px 50px;
    border-radius: 30px;
    color: white;
    margin-top: -30px;
}

.form {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 44px;
    box-sizing: border-box;
}

.title {
    text-align: center;
    margin: -10px 0;
    font-size: 35px;
    font-weight: 900;
    padding: 0;
    line-height: 35px;
}

.input {
    color: white;
    background-color: #3a2d49;
    width: 500px;
    height: 50px;
    border-radius: 50px;
    padding: 5px 0 5px 15px;
    border: 0;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
}

.input:focus {
    box-shadow: 0 0 3px #BB86FC;
}

.input::placeholder {
    color: #e6bbff;
    opacity: 1;
}
.input__container {
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    gap: 20px;
}

.button {
    transition: 0.2s ease opacity;
    margin-top: 10px;
    padding: 10px 30px;
    border: none;
    outline: none;
    background-color: #BB86FC;
    height: 60px;
    width: 400px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
.button:hover {
    opacity: 80%;
}
.text {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    gap: 15px;
}

.text__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    gap: 5px;
}

.subtitle {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
}

.link {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
    color: #e6bbff;
}

@media (max-width: 800px) {
    .main {
        align-self: start;
        margin-top: 35px;
        gap: 35px;
    }

    .form {
        gap: 35px;
    }

    .title {
        font-size: 32px;
        line-height: 32px;
    }

    .input {
        width: 400px;
        height: 40px;
        border-radius: 40px;
        font-size: 20px;
        line-height: 20px;
    }

    .input__container {
        gap: 15px;
    }

    .button {
        margin-top: 10px;
        padding: 10px 30px;
        height: 50px;
        width: 250px;
        border-radius: 40px;
        font-size: 20px;
        line-height: 20px;
    }
    .text {
        margin-top: 0;
        gap: 13px;
    }

    .text__container {
        flex-direction: column;
        gap: 7px;
    }

    .subtitle {
        font-size: 18px;
        line-height: 18px;
    }

    .link {
        font-size: 18px;
        line-height: 18px;
    }

}

@media (max-width: 500px) {
    .main {
        margin-top: 0;
    }


    .title {
        font-size: 28px;
        line-height: 28px;
    }

    .input {
        width: 280px;
        height: 35px;
        border-radius: 20px;
        font-size: 16px;
        line-height: 16px;
    }

    .input__container {
        gap: 10px;
    }

    .button {
        margin-top: 10px;
        padding: 10px 30px;
        height: 40px;
        width: 180px;
        border-radius: 35px;
        font-size: 16px;
        line-height: 16px;
    }

    .text {
        margin-top: -15px;
        gap: 13px;
    }

    .subtitle {
        font-size: 15px;
        line-height: 15px;
    }

    .link {
        font-size: 15px;
        line-height: 15px;
    }

}

@media (max-width: 400px) {

    .title {
        font-size: 26px;
        line-height: 26px;
    }

    .input {
        width: 260px;
        height: 33px;
    }

    .button {
        margin-top: 3px;
        height: 35px;
        width: 160px;
        border-radius: 32px;
        font-size: 15px;
        line-height: 15px;
    }

    .text {
        margin-top: -25px;
    }

    .text__container {
        gap: 3px;
    }

}