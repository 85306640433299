.main {
    display: grid;
    width: 80%;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto;
    column-gap: 50px;
    row-gap: 30px;
}

.stars {
    display: flex;
    align-content: center;
    flex-direction: row;
    gap: 20px;
    min-height: 65px;
    place-content: center;
}


.rating__button {
    margin-bottom: 20px;
    padding: 0;
    outline: none;
    height: 45px;
    width: 150px;
    border-radius: 150px;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    background-color: #241f2c;
    color: #BB86FC;
    border: 2px solid #BB86FC;
    cursor: pointer;
    transition: 0.2s ease opacity;
}

.delete__button {
    transition: 0.2s all ease;
    margin: 0;
    padding: 0;
    outline: none;
    height: 50px;
    width: 250px;
    border-radius: 17px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    background-color: rgba(74, 31, 44, 0.7);
    color: #E5E5E5;
    border: 2px solid #7a1f2c;
    cursor: pointer;
}

.star__group {
    display: grid;
    font-size: 50px;
    grid-auto-flow: column;
}

.p_star {
    align-items: center;
    appearance: none;
    cursor: pointer;
    display: grid;
    font: inherit;
    height: 50px;
    justify-items: center;
    margin: 0;
    place-content: center;
    position: relative;
    width: 50px;
}

.p_star:before {
    content: "★";
}

.star {
    align-items: center;
    appearance: none;
    cursor: pointer;
    display: grid;
    font: inherit;
    height: 50px;
    justify-items: center;
    margin: 0;
    place-content: center;
    position: relative;
    width: 50px;
}

@media (prefers-reduced-motion: no-preference) {
    .star {
        transition: all 0.25s;
    }

    .star:before,
    .star:after {
        transition: all 0.25s;
    }
}

.star:before,
.star:after {
    color: gold;
    position: absolute;
}

.p_star:before,
.p_star:after {
    color: gold;
    position: absolute;
}


.star:before {
    content: "☆";
}
.star:checked:before,
.star:has(~ .star:checked):before {
    content: "★";
}

.album {
    grid-row: span 2;
    width: 100%;
    aspect-ratio: 1/1.4;
    position: relative;
    border-radius: 40px;
}

.left__button:hover {
    opacity: 1;
}

.left__button {
    opacity: 0;
    margin-left: 10px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-items: left;
    align-items: start;
    justify-content: center;
    align-content: start;
    width: 50%;
    height: 100%;
    top: 0;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    left: 0;
    transition: 0.2s;
}

.right__button:hover {
    opacity: 1;
}

.right__button {
    transition: 0.2s ease opacity;
    opacity: 0;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-items: right;
    align-items: end;
    justify-content: center;
    align-content: end;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
}

.image {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 40px;
    object-fit: cover;
}

.image__button {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.title {
    margin: 0;
    font-size: 32px;
    color: white;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
}

.description {
    color: white;
    margin: 0;
    overflow-wrap: break-word;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
}

.data {
    color: #939393;
    align-self: end;
    margin: 0;
    opacity: 85%;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
}

.note {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: start;
    justify-content: start;
    align-content: space-between;
    gap: 25px;
}

.profile {
    margin-top: 15px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 15px;
    color: white;
}

.avatar {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    object-fit: cover;
}

.name {
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
}

.rating {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    align-content: center;
}

.rating__text {
    margin: 0;
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #939393;
}

.rating__button:hover {
    opacity: 85%;
}

.buttons {
    justify-self: end;
    align-self: start;
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.delete__button:hover {
    border: 2px solid #b71f2c;
    opacity: 85%;

}


.save_button {
    transition: 0.2s opacity ease;
    margin-bottom: 20px;
    padding: 0;
    outline: none;
    height: 65px;
    width: 300px;
    border-radius: 50px;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    background-color: #241f2c;
    color: #BB86FC;
    border: 2px solid #BB86FC;
    cursor: pointer;
}

.link {
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    height: 100%;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: white;
}

.save_button:hover {
    opacity: 85%;
}

.button {
    transition: 0.2s opacity ease;
    margin-bottom: 20px;
    padding: 0;
    border: none;
    outline: none;
    background-color: #BB86FC;
    height: 65px;
    width: 300px;
    border-radius: 50px;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.button:hover {
    opacity: 80%;
}

@media (max-width: 1200px) {
    .main {
        align-self: start;
        margin-top: 70px;
        width: 85%;
        column-gap: 40px;
        row-gap: 20px;
    }

    .album {
        border-radius: 30px;
    }

    .left__button {
        opacity: 0;
    }

    .right__button {
        opacity: 0;
    }

    .image {
        overflow: hidden;
        border-radius: 30px;
    }

    .image__button {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }

    .title {
        font-size: 28px;
        line-height: 28px;
    }

    .description {
        font-size: 20px;
        line-height: 20px;
    }

    .note {
        gap: 20px;
    }

    .profile {
        margin-top: 10px;
        gap: 15px;
    }

    .avatar {
        width: 55px;
        height: 55px;
    }

    .name {
        font-size: 20px;
        line-height: 20px;
    }

    .buttons {
        flex-direction: column;
        gap: 0;
    }

    .delete__button {
        height: 45px;
        width: 180px;
        border-radius: 17px;
        font-size: 16px;
        line-height: 16px;
    }

    .save_button {
        height: 50px;
        width: 250px;
        border-radius: 40px;
        font-size: 21px;
        line-height: 21px;
    }
    .link {
        font-size: 21px;
        line-height: 21px;
    }


    .button {
        height: 50px;
        width: 250px;
        border-radius: 40px;
        font-size: 21px;
        line-height: 21px;
    }
    .rating {
        gap: 10px;
    }

    .rating__text {
        font-size: 28px;
        line-height: 28px;
    }
    .data {
        font-size: 20px;
        line-height: 20px;
    }
    .rating__button {
        height: 45px;
        width: 140px;
        border-radius: 150px;
        font-size: 21px;
        line-height: 21px;
    }
    .star__group {
        font-size: 45px;
    }

    .star {
        height: 45px;
        width: 45px;
    }
    .p_star {
        height: 45px;
        width: 45px;
    }
    .stars {
        gap: 15px;
        min-height: 60px;
    }
}

@media (max-width: 800px) {
    .main {
        margin-top: 40px;
        width: 90%;
        column-gap: 30px;
        row-gap: 12px;
    }

    .album {
        border-radius: 30px;
    }

    .left__button {
        opacity: 1;
    }

    .right__button {
        opacity: 1;
    }

    .image {
        border-radius: 30px;
    }

    .image__button {
        width: 35px;
        height: 35px;
    }

    .title {
        font-size: 18px;
        line-height: 17px;
    }

    .description {
        font-size: 16px;
        line-height: 16px;
    }

    .note {
        gap: 10px;
    }

    .profile {
        margin-top: 5px;
        gap: 10px;
    }

    .avatar {
        width: 40px;
        height: 40px;
    }

    .name {
        font-size: 16px;
        line-height: 16px;
    }

    .delete__button {
        height: 35px;
        width: 150px;
        border-radius: 15px;
        font-size: 14px;
        line-height: 14px;
    }

    .save_button {
        margin-bottom: 10px;
        height: 40px;
        width: 200px;
        border-radius: 30px;
        font-size: 16px;
        line-height: 16px;
    }

    .link {
        font-size: 16px;
        line-height: 16px;
    }

    .button {
        height: 40px;
        width: 200px;
        border-radius: 30px;
        font-size: 16px;
        line-height: 16px;
    }
    .rating {
        gap: 7px;
    }
    .rating__text {
        font-size: 22px;
        line-height: 22px;
    }
    .data {
        font-size: 16px;
        line-height: 16px;
    }
    .rating__button {
        height: 35px;
        width: 100px;
        font-size: 16px;
        line-height: 16px;
    }
    .star__group {
        font-size: 40px;
    }

    .star {
        height: 40px;
        width: 40px;
    }
    .p_star {
        height: 40px;
        width: 40px;
    }
    .stars {
        gap: 10px;
        min-height: 40px;
    }
}

@media (max-width: 550px) {
    .main {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        margin-top: 30px;
        row-gap: 25px;
    }

    .delete__button {
        height: 33px;
        width: 115px;
        font-size: 12px;
        line-height: 12px;
    }

    .rating {
        gap: 5px;
    }

    .note {
        order: -1;
    }
    .buttons {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .button {
        margin-top: 10px;
    }

    .rating__button {
        height: 35px;
        width: 100px;
        font-size: 16px;
        line-height: 16px;
    }

    .star__group {
        font-size: 35px;
    }

    .star {
        height: 35px;
        width: 35px;
    }
    .p_star {
        height: 35px;
        width: 35px;
    }
    .stars {
        width: 100%;
        justify-content: space-between;
        min-height: 35px;
    }
}

@media (max-width: 450px) {
    .buttons {
        width: auto;
        align-self: start;
        flex-direction: column;
    }
    .main {
        margin-top: 15px;
        row-gap: 10px;
    }


    .note {
        order: -1;
    }

    .button {
        margin-top: 5px;
    }
}