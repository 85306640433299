.main {
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    overflow-x: hidden;
    background-color: white;
}

.mainDark {
    background-color: #121212;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    height: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    overflow-x: hidden;
}