
.profile {
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: start;
    gap: 32px;
}

.sub__button {
    border: 1px solid #007aef;
    margin: 0;
    transition: 0.2s ease opacity;
    padding: 0;
    outline: none;
    background-color: white;
    height: 50px;
    width: 180px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    box-shadow: 0 0 1px #007aef;
    color: #007aef;
    cursor: pointer;
}


.sub__button:hover {
    opacity: 85%;
}

.unsub__button {
    border: 1px solid #007aef;
    margin: 0;
    transition: 0.2s ease all;
    padding: 0;
    outline: none;
    background-color: #007aef;
    height: 50px;
    width: 180px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    box-shadow: 0 0 1px #007aef;
    color: white;
    cursor: pointer;
}

.unsub__button:hover {
    opacity: 85%;
}

.pagination__buttons {
    display: flex;
    gap: 20px;
    margin-top: 30px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
}

.pagination__button__left {
    rotate: 180deg;
    margin: 0;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    transition: 0.2s ease opacity;
    outline: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.pagination__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pagination__button__right {
    margin: 0;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    transition: 0.2s ease opacity;
    outline: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.pagination__button__right:hover {
    opacity: 85%;
}

.pagination__button__left:hover {
    opacity: 85%;
}

.line {
    width: 85%;
    color: #f0f0f0;
    opacity: 0.3;
    height: 1px;
    box-sizing: border-box;
}

.name {
    margin: 0;
    padding: 0;
    font-size: 32px;
    font-weight: 900;
    line-height: 32px;
}

.add__button {
    margin: 0;
    transition: 0.2s ease opacity;
    padding: 0;
    border: none;
    outline: none;
    background-color: #007aef;
    height: 50px;
    width: 180px;
    border-radius: 50px;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
.add__button:hover {
    opacity: 85%;
}

.table {
    gap: 28px;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
}

.subtitle {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #5d5d5b;
}

.text {
    gap: 15px;
    flex-direction: column;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.button:hover {
    opacity: 80%;
}

.button__image {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.skeleton__avatar {
    width: 120px;
    height: 120px;
    border-radius: 10px;
}

.avatar {
    width: 120px;
    height: 120px;
    border-radius: 10px;
}

.cards__list {
    display: grid;
    column-gap: 25px;
    row-gap: 25px;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    justify-content: center;
    align-items: center;
}


.tabs {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    width: 85%;
    justify-content: center;
    gap: 10px;
}

@media (max-width: 1500px) {
    .cards__list {
        grid-template-columns: repeat(4, 1fr);
    }

}
@media (max-width: 1275px) {
    .cards__list {
        grid-template-columns: repeat(3, 1fr);
    }

}

@media (max-width: 950px) {
    .cards__list {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media (max-width: 615px) {
    .sub__button {
        font-size: 16px;
        line-height: 16px;
        width: 150px;
    }
    .unsub__button {
        font-size: 16px;
        line-height: 16px;
        width: 150px;
    }
    .profile {
        margin-top: 30px;
        gap: 26px;
    }

    .name {
        font-size: 28px;
        line-height: 28px;
    }

    .add__button {
        width: 150px;
    }

    .table {
        gap: 18px;
    }

    .title {
        font-size: 14px;
        line-height: 14px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 14px;
    }

    .text {
        gap: 12px;
    }

    .avatar {
        width: 90px;
        height: 90px;
    }

    .tabs {
        font-size: 14px;
        line-height: 14px;
    }

}


@media (max-width: 450px) {
    .sub__button {
        margin-top: -10px;
        height: 35px;
        width: 110px;
        font-size: 13px;
        line-height: 13px;
    }
    .unsub__button {
        margin-top: -10px;
        height: 35px;
        width: 110px;
        font-size: 13px;
        line-height: 13px;
    }
    .profile {
        margin-top: 20px;
        gap: 22px;
    }

    .name {
        font-size: 22px;
        line-height: 22px;
    }

    .add__button {
        margin-top: -10px;
        height: 35px;
        width: 110px;
    }

    .table {
        gap: 12px;
    }

    .text {
        gap: 8px;
    }

    .avatar {
        width: 75px;
        height: 75px;
    }

    .tabs {
        font-size: 11px;
        line-height: 11px;
        gap: 0;
    }
    .cards__list {
        grid-template-columns: repeat(1, 1fr);
    }
}