.search__bar {
    width: 100%;
    border-radius: 200px;
    box-shadow: 0 0 5px #c1c0c8;
    background-color: white;
}

.input__box {
    position: relative;
    display: flex;
    align-items: center;
    margin: 5px 20px;
    justify-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: row;
}


.input {
    width: 100%;
    font-size: 32px;
    padding-left: 20px;
    border: none;
    outline: none;
}

.text {
    margin: 0;
    padding: 0;
    font-size: 32px;
    font-weight: 400;
    line-height: 32px;
}

.input:placeholder-shown + .placeholder {
    opacity: 1;
}

.placeholder {
    opacity: 0;
    position: absolute;
    left: 20px;
    top: 10px;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: #989898;
}

::placeholder {
    color: #989898;
    opacity: 1; /* Firefox */
}

.slider__open {
    position: absolute;
    opacity: 1;
    left: 0;
    font-size: 32px;
    transition: 0.5s;
}

.slider__close {
    position: absolute;
    opacity: 0;
    left: -5px;
    font-size: 32px;
    transition: 0.5s;
}


.slider__box {
    margin-left: 175px;
    height: 32px;
    width: 450px;
    position: relative;
    box-sizing: border-box;
}

.button {
    flex-shrink: 0;
    padding: 0;
    height: 50px;
    width: 50px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
}

.search__icon {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

@media (max-width: 1000px) {
    .search__bar {
        border-radius: 150px;
    }

    .input__box {
        margin: 5px 15px;
    }


    .input {
        font-size: 26px;
    }

    .text {
        font-size: 26px;
        line-height: 26px;
    }

    .placeholder {
        left: -10px;
        top: 9px;
    }

    .slider__open {
        font-size: 26px;
    }

    .slider__close {
        left: -5px;
        font-size: 26px;
    }


    .slider__box {
        height: 26px;
    }

    .button {
        height: 40px;
        width: 40px;
    }

    .search__icon {
        width: 40px;
        height: 40px;
    }

}
@media (max-width: 600px) {
    .search__bar {
        border-radius: 115020px;
    }

    .input__box {
        margin: 3px 10px;
    }


    .input {
        font-size: 20px;
    }

    .text {
        font-size: 20px;
        line-height: 20px;
    }

    .placeholder {
        left: -10px;
        top: 6px;
    }

    .slider__open {
        font-size: 20px;
    }

    .slider__close {
        left: -5px;
        font-size: 20px;
    }


    .slider__box {
        margin-left: 140px;
        height: 20px;
    }

    .button {
        height: 30px;
        width: 30px;
    }

    .search__icon {
        width: 30px;
        height: 30px;
    }

}

@media (max-width: 460px) {
    .slider__open {
        opacity: 0;
    }
}
