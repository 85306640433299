.card {
    width: 250px;
    height: 350px;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    border-radius: 15px;
    box-shadow: 0 0 5px #c1c0c8;
}


.image {
    cursor: pointer;
    height: 220px;
    width: 220px;
    border-radius: 25px;
    object-fit: cover;
}

.text {
    width: 100%;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-items: start;
    align-items: start;
    justify-content: start;
    align-content: start;
}

.name {
    height: 60px;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
    text-align: start;
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: black;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;
}

.container {
    width: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: end;
    align-content: center;
}

@media (max-width: 615px) {
    .card {
        width: 200px;
        height: 290px;
        padding: 15px 15px;
    }

    .image__button {
        width: 170px;
        height: 170px;
    }

    .image {
        height: 170px;
        width: 170px;
    }

    .text {
        margin: 12px 0;
    }

    .name {
        font-size: 18px;
        line-height: 18px;
    }

    .description {
        font-size: 13px;
        line-height: 13px;
    }

    .price {
        font-size: 17px;
        line-height: 17px;
    }

    .button {
        height: 13px;
        padding: 16px 12px;
    }
    .button__text {
        font-size: 15px;
        line-height: 15px;
    }

    .button__inactive {
        height: 13px;
        padding: 16px 12px;
    }

}