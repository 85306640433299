.filter {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    border-bottom: 1px solid #c1c0c8;
    padding: 5px 0;
}

.button {
    border: none;
    flex-shrink: 0;
    padding: 0;
    width: 100%;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    justify-items: center;
    align-content: center;
}

.title {
    padding-left: 10px;
    color: black;
    margin: 0;
    font-size: 22px;
    font-weight: 400;
    line-height: 22px;
}

.image {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.image__open {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    object-fit: cover;
    transform: rotate(180deg);
}

.container {
    display: none;
}

.container__open {
    margin-bottom: 5px;
    padding: 0 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
}

@media (max-width: 1600px) {
    .filter {
        width: 280px;
    }
}

@media (max-width: 700px) {
    .filter {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .title {
        font-size: 18px;
        line-height: 18px;
    }

    .image {
        width: 20px;
        height: 20px;
    }

    .image__open {
        width: 20px;
        height: 20px;
    }

}