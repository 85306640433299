.input {
    color: white;
    background-color: #3a2d49;
    border-radius: 50px;
    width: 100%;
    margin-top: -5px;
    padding: 0 10px 5px;
    border: 0;
    font-size: 17px;
    font-weight: 400;
    line-height: 17px;
}

.buttons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    column-gap: 5px;
}

.button__active {
    width: 100%;
    transition: 0.2s ease opacity;
    margin-top: 10px;
    align-self: end;
    padding: 0;
    border: none;
    outline: none;
    background-color: #BB86FC;
    height: 45px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #fff;
}

.button__inactive {
    width: 100%;
    transition: 0.2s ease opacity;
    margin-top: 10px;
    align-self: end;
    padding: 0;
    background-color: #241f2c;
    color: #BB86FC;
    border: 2px solid #BB86FC;
    outline: none;
    height: 45px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
}

.button__inactive:hover {
    opacity: 85%;
}



.input:focus {
    outline: none !important;
}

.label {
    color: white;
    font-size: 17px;
    font-weight: 400;
    line-height: 17px;
}


.label input[type='radio'] {
    appearance: none;
    margin-right: 5px;
    width: 12px;
    height: 12px;
    border: 1px solid #BB86FC;
    border-radius: 50%;
    outline: none;
    box-shadow: none;
    transition: box-shadow 0.3s ease;
}

.label input[type='radio']:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 50%;
}

.label input[type='radio']:checked:before {
    background: #BB86FC;
}

.inputs {
    margin-left: 7px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: start;
    justify-content: center;
    justify-items: center;
    align-content: center;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start;
    justify-content: center;
    justify-items: center;
    align-content: center;
}

.button {
    transition: 0.2s ease opacity;
    margin-top: 10px;
    align-self: end;
    padding: 0;
    border: none;
    outline: none;
    background-color: #BB86FC;
    height: 45px;
    width: 180px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.button:hover {
    opacity: 85%;
}

@media (max-width: 700px) {
    .main {
        width: 100%;
    }
    .button {
        height: 40px;
        width: 160px;
        border-radius: 40px;
        font-size: 18px;
        margin-bottom: -15px;
        line-height: 18px;
    }

}