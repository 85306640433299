.search {
    width: 70%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: start;
    gap: 32px;
}

.notes {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.pagination__button__left {
    rotate: 180deg;
    margin: 0;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    transition: 0.2s ease opacity;
    outline: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.pagination__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pagination__buttons {
    display: flex;
    gap: 20px;
    margin: 30px 0;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
}

.pagination__button__right {
    margin: 0;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    transition: 0.2s ease opacity;
    outline: none;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.pagination__button__right:hover {
    opacity: 85%;
}

.pagination__button__left:hover {
    opacity: 85%;
}

.cards__list {
    width: 100%;
    display: grid;
    column-gap: 5px;
    row-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    justify-content: center;
    align-items: center;
}

.container {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: start;
    justify-content: center;
    align-content: center;
    align-self: center;
    gap: 15px;
}

@media (max-width: 1500px) {
    .search {
        width: 82%;
        margin-top: 40px;
        gap: 15px;
    }
}

@media (max-width: 1300px) {
    .search {
        margin-top: 35px;
        gap: 12px;
    }

    .cards__list {
        column-gap: 15px;
        row-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1000px) {
    .search {
        width: 90%;
        margin-top: 30px;
        gap: 5px;
    }

    .cards__list {
        width: 100%;
        column-gap: 10px;
        row-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }

    .container {
        margin-top: 15px;
        gap: 10px;
    }
}

@media (max-width: 900px) {
    .search {
        margin-top: 25px;
    }

    .cards__list {
        width: 100%;
        column-gap: 10px;
        row-gap: 20px;
        grid-template-columns: repeat(1, 1fr);
    }

    .container {
        margin-top: 15px;
        gap: 10px;
    }
}

@media (max-width: 700px) {
    .search {
        margin-top: 25px;
    }

    .cards__list {
        margin-top: 20px;
        width: 100%;
        column-gap: 10px;
        row-gap: 20px;
        grid-template-columns: 1fr 1fr;
    }

    .container {
        flex-direction: column;
        margin-top: 15px;
        gap: 10px;
    }
}

@media (max-width: 450px) {
    .search {
        margin-top: 15px;
        gap: 0;
    }

    .cards__list {
        row-gap: 15px;
        grid-template-columns: 1fr;
    }

}