.main {
    display: flex;
    width: 75%;
    height: 500px;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 30px;
    position: relative;
}

.image1 {
    z-index: 100;
    width: 220px;
    height: 220px;
    position: absolute;
    top: 0;
    left: 0;
}

.image2 {
    z-index: 100;
    width: 220px;
    height: 220px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.title {
    z-index: 101;
    margin: 0;
    font-size: 56px;
    font-weight: 600;
    line-height: 56px;
}

.buttons {
    z-index: 101;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.entry {
    z-index: 101;
    margin: 0;
    padding: 0;
    outline: none;
    background-color: white;
    height: 65px;
    width: 200px;
    border-radius: 60px;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #007aef;
    border: 2px solid #007aef;
    cursor: pointer;
    transition: 0.2s ease opacity;
}


.signup {
    z-index: 101;
    transition: 0.2s ease opacity;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background-color: #007aef;
    height: 65px;
    width: 200px;
    border-radius: 60px;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.signup:hover {
    z-index: 101;
    opacity: 80%;
}

.entry:hover {
    z-index: 101;
    opacity: 80%;
}

@media (max-width: 1600px) {
    .main {
        width: 85%;
    }
}

@media (max-width: 1400px) {
    .main {
        gap: 25px;
    }

    .image1 {
        width: 180px;
        height: 180px;
    }

    .image2 {
        width: 180px;
        height: 180px;
    }

    .title {
        font-size: 52px;
        line-height: 52px;
    }

    .buttons {
        margin-top: 25px;
    }

    .entry {
        height: 55px;
        width: 180px;
        font-size: 22px;
        line-height: 22px;
    }


    .signup {
        height: 55px;
        width: 180px;
        font-size: 22px;
        line-height: 22px;
    }
}

@media (max-width: 1200px) {
    .main {
        gap: 20px;
    }

    .image1 {
        width: 170px;
        height: 170px;
    }

    .image2 {
        width: 170px;
        height: 170px;
    }

    .title {
        font-size: 46px;
        line-height: 46px;
    }

    .buttons {
        margin-top: 20px;
    }

    .entry {
        height: 50px;
        width: 170px;
        border-radius: 50px;
    }


    .signup {
        height: 50px;
        width: 170px;
        border-radius: 50px;
    }
}

@media (max-width: 1000px) {
    .main {
        gap: 15px;
    }

    .image1 {
        width: 150px;
        height: 150px;
    }

    .image2 {
        width: 150px;
        height: 150px;
    }

    .title {
        font-size: 40px;
        line-height: 40px;
    }

    .buttons {
        margin-top: 16px;
    }

    .entry {
        height: 50px;
        width: 170px;
        border-radius: 50px;
    }


    .signup {
        height: 50px;
        width: 170px;
        border-radius: 50px;
    }
}

@media (max-width: 600px) {
    .main {
        margin-top: 40px;
        align-self: start;
        height: 420px;
        gap: 15px;
    }

    .image1 {
        width: 130px;
        height: 130px;
    }

    .image2 {
        width: 130px;
        height: 130px;
    }

    .title {
        font-size: 30px;
        line-height: 30px;
    }

    .buttons {
        margin-top: 8px;
    }

    .entry {
        height: 45px;
        width: 140px;
        border-radius: 50px;
        font-size: 16px;
        line-height: 16px;
    }


    .signup {
        font-size: 16px;
        line-height: 16px;
        height: 45px;
        width: 140px;
        border-radius: 50px;
    }
}

@media (max-width: 450px) {
    .main {
        width: 90%;
        margin-top: 25px;
        align-self: start;
        height: 350px;
        gap: 8px;
    }

    .image1 {
        width: 120px;
        height: 120px;
    }

    .image2 {
        width: 120px;
        height: 120px;
    }

    .title {
        font-size: 22px;
        line-height: 22px;
    }

    .buttons {
        margin-top: 5px;
        gap: 4px;
    }

    .entry {
        height: 35px;
        width: 90px;
        border-radius: 50px;
        font-size: 13px;
        line-height: 13px;
    }


    .signup {
        font-size: 13px;
        line-height: 13px;
        height: 35px;
        width: 90px;
        border-radius: 50px;
    }
}