@font-face {
    src: url("fonts/Roboto-Black.ttf") format('truetype');
    font-family: 'Roboto';
    font-weight: 900;
}

@font-face {
    src:
    url("fonts/Roboto-Bold.ttf") format('truetype');
    font-family: 'Roboto';
    font-weight: 700;
}

@font-face {
    src: url("fonts/Roboto-Italic.ttf") format('truetype');
    font-family: 'Italic';
    font-weight: 400;
}
@font-face {
    src: url("fonts/Roboto-Medium.ttf") format('truetype');
    font-family: 'Roboto';
    font-weight: 500;
}
@font-face {
    src: url("fonts/Roboto-Regular.ttf") format('truetype');
    font-family: 'Roboto';
    font-weight: 400;
}